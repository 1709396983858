<template>
<div class="container padding-container">
    <el-button class="topBtns" type="primary" icon="el-icon-plus" @click="showDeviceVisible">新增设备</el-button>
    <div class="border-container">
        <el-row class="search-container" type="flex" align="middle" justify="space-between">
            <el-col :lg="2" :sm="4" :xs="8">
                <span class="list-title">设备列表</span>
            </el-col>
            <el-col :lg="22" :sm="20" :xs="16" class="search-area">
                <el-col :span='2.5' class="search-item">
                    <el-select v-model="searchForm.type" clearable placeholder="请选择设备类型" @change="pageChange(1)">
                        <el-option v-for="item in deviceTypelList" :key="item.id" :label="item.name" :value="item.id"/>
                    </el-select>
                </el-col>
                <el-col :span='2.5' class="search-item" v-if="auth('ORG')">
                    <el-select v-model="searchForm.school_id" clearable placeholder="请选择所属学校" @change="pageChange(1)">
                        <el-option v-for="item in schoolList" :key="item.id" :label="item.name" :value="item.id"/>
                    </el-select>
                </el-col>
                <el-col :span='2.5' class="search-item">
                    <el-input suffix-icon="el-icon-search" v-model="searchForm.device_id" style="width: 184px;"
                        placeholder="搜索设备序列号" @change="pageChange(1)"></el-input>
                </el-col>
                <el-col :span='2.5' class="search-item">
                    <el-input suffix-icon="el-icon-search" v-model="searchForm.name" style="width: 184px;"
                        placeholder="搜索通道名称" @change="pageChange(1)"></el-input>
                </el-col>
            </el-col>
        </el-row>

        <div class="table-container">
            <el-table :data="tableData" ref="multipleTable" @selection-change="handleSelectionChange"
                tooltip-effect="dark" style="width: 100%" size="medium" :row-key="item => item.id"
                :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
                :cell-style="{height: '50px',padding: '5px 0'}" header-align="center">
                <el-table-column type="selection" width="55" align="center"/>
                <el-table-column prop="id" label="ID" width="60" align="center" v-if='auth("ADMIN")'/>
                <el-table-column prop="serial_number" label="设备序列号" width="120" :show-overflow-tooltip="true">
                    <div slot-scope="scope" class="hover-text-colourful fz-bold" @click="routerChange(1, scope.row)">
                        {{scope.row.serial_number}}
                    </div>
                </el-table-column>
                <el-table-column prop="channel" label="通道号" width="80"/>
                <el-table-column prop="type" label="设备类型" width="120">
                    <template slot-scope="scope">
                        <i class="iconfont" :class="scope.row.type | deviceTypeIconFilter"></i>
                        {{scope.row.type | deviceTypeFilter}}
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="通道名称" min-width="200" :show-overflow-tooltip="true"/>
                <el-table-column prop="school_name" label="所属学校" min-width="155" :show-overflow-tooltip="true"/>
                <el-table-column label="操作" min-width="180">
                    <div slot-scope="scope" class="row">
                        <device-diagnose class="mr-10" btnType='text' :item="scope.row"/>
                        <el-button class="mr-10" type="text" size="small" @click="openEditVisible(scope.row)">编辑</el-button>
                        <open-time class="mr-10" @refresh='getTableData' :config="scope.row.config" :id="scope.row.id" v-if="auth('class.camera.settime')"/>
                        <el-button type="text" v-if="scope.row.type != 2" size="small" @click="openClassVisible(scope.row)">关联班级</el-button>
                    </div>
                </el-table-column>
                <div class="" slot="empty">
                    <no-data></no-data>
                </div>
            </el-table>
        </div>
        <div class="page-container with-multiple">
            <div class="left">
                <div class="selected-sum">
                    <span>已选中 {{multipleSelection.length || 0}} 条</span>
                </div>
                <div class="btn-area" v-if="auth('class.camera.settime')">
                    <open-time buttonType='' btnText='批量设置开放时间' :ids="multipleSelectionIds"
                        :btnDisabled="!multipleSelectionIds.length" :refresh='getTableData'></open-time>
                </div>
            </div>
            <el-pagination class="right" layout="total, sizes, prev, pager, next, jumper" background
                @size-change="pageSizeChange" @current-change="pageChange" :current-page="currPage" :total="total"
                :page-size="pageSize" :page-sizes="[10, 20, 50, 100]">
            </el-pagination>
        </div>
    </div>

    <el-dialog title="关联班级" width="504px" :visible.sync="classVisible" :before-close="closeClassViseble">
        <div class="form-item">
            <div class="key">关联班级</div>
            <el-select class="value" v-model="dialogForm.class_id" placeholder="请选择关联班级">
                <el-option :label="item.name" :value="item.id" :key="item.id" v-for="item in classList" />
            </el-select>
        </div>
        <span slot="footer" class="dialog-footer" style="display: flex;justify-content: center">
            <el-button @click="closeClassViseble">取消</el-button>
            <el-button type="primary" @click="handleClass">确定</el-button>
        </span>
    </el-dialog>
    <el-dialog title="新增设备" width="550px" :visible.sync="deviceVisible" custom-class="device-dialog" :before-close="closeDeviceViseble">
        <div class="form-item" v-if="active === 1">
            <div class="key">序列号
                <el-tooltip class="item" style="margin-left: 10px" effect="dark" content="设备上的序列号"
                    placement="top-start">
                    <i class="el-icon-info"></i>
                </el-tooltip>
            </div>
            <el-input class="value" v-model="dialogForm.device_id" placeholder="请输入设备序列号"></el-input>
        </div>
        <div v-if="active === 2" v-loading="loading">
            <el-table :data="channelList" ref="multipleTableChannel"
                @selection-change="handleSelectionChannelChange" :row-key="item => item.channelNo"
                :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
                :cell-style="{height: '50px',padding: '5px 0'}" header-align="center">
                <el-table-column type="selection" width="55" align="center"></el-table-column>
                <el-table-column prop="channelNo" label="通道号" width="100"></el-table-column>
                <el-table-column prop="channelName" label="通道名称"></el-table-column>
            </el-table>
        </div>
        <template v-if="active === 3">
            <div class="form-item">
                <div class="key">设备类型
                    <el-tooltip class="item" style="margin-left: 10px" effect="dark" content="设备类型"
                        placement="top-start">
                        <i class="el-icon-info"></i>
                    </el-tooltip>
                </div>
                <div class="value">
                    <el-radio v-model="dialogForm.type" :label="1">班级</el-radio>
                    <el-radio v-model="dialogForm.type" :label="2">公共区域</el-radio>
                </div>
            </div>
            <div class="form-item" v-if="auth('ORG')">
                <div class="key">所属学校
                    <el-tooltip class="item" style="margin-left: 10px" effect="dark" content="该摄像头属于哪个学校"
                        placement="top-start">
                        <i class="el-icon-info"></i>
                    </el-tooltip>
                </div>
                <el-select class="value" v-model="dialogForm.school_id" placeholder="请选择所属学校">
                    <el-option :label="item.name" :value="item.id" :key="item.id" v-for="item in schoolList">
                    </el-option>
                </el-select>
            </div>
            <div class="form-item channel-name">
                <div class="key">通道名称
                    <el-tooltip class="item" style="margin-left: 10px" effect="dark" content="摄像头所属位置"
                        placement="top-start">
                        <i class="el-icon-info"></i>
                    </el-tooltip>
                </div>
                <div class="value">
                    <div class="form-item-channel" v-for="(item, index) in multipleSelectionChannelNo" :key="index">
                        <div class="key-channel">通道 {{item.channel}}</div>
                        <el-input class="value-channel" v-model="item.name" placeholder="请输入通道名称"></el-input>
                    </div>
                </div>
            </div>
        </template>
        <span slot="footer" class="dialog-footer" style="display: flex;justify-content: center">
            <el-button v-if="active === 1" @click="closeDeviceViseble">取消</el-button>
            <el-button v-if="active !== 1" @click="lastStep">上一步</el-button>
            <el-button type="primary" @click="next" v-if="active !== 3">下一步</el-button>
            <el-button type="primary" @click="handleDeviceAdd" v-if="active === 3">确定</el-button>
        </span>
    </el-dialog>
    <el-dialog title="编辑设备基本信息" width="504px" :visible.sync="editVisible" :before-close="closeEditViseble">
        <div class="form-item">
            <div class="key">设备类型</div>
            <div class="value">
                <el-radio v-model="editForm.type" :label="1">班级</el-radio>
                <el-radio v-model="editForm.type" :label="2">公共区域</el-radio>
            </div>
        </div>
        <div class="form-item">
            <div class="key">通道名称</div>
            <el-input class="value" v-model="editForm.name" placeholder="请输入通道名称"></el-input>
        </div>
        <span slot="footer" class="dialog-footer" style="display: flex;justify-content: center">
            <el-button @click="closeEditViseble">取消</el-button>
            <el-button type="primary" @click="handleEdit">确定</el-button>
        </span>
    </el-dialog>
</div>
</template>

<script>
import Core from '@/core';
import axios from 'axios';
axios.defaults.headers.post['Content-Type'] = 'application/json';
import { baseUrl } from '@/core/api/config';
export default {
    components: {
        NoData: () => import('@/components/Empty.vue'),
        OpenTime: () => import('./components/OpenTime'),
        DeviceDiagnose: () => import('./components/DeviceDiagnose'),
    },
    props: {},
    data() {
        return {
            total: 0,
            currPage: 1,
            pageSize: 10,

            searchForm: {
                school_id: '',
                name: '',
                device_id: '',
                type: ''
            },
            classifyList: [],
            deviceTypelList: [
                { id: 1, name: '班级' },
                { id: 2, name: '公共区域' }
            ],
            tableData: [],
            classVisible: false,
            deviceVisible: false,
            taskTime: '',
            schoolList: [],
            classList: [],
            dialogForm: {
                id: 0,
                device_id: '',
                serial_num: '',
                name: '',
                channel: '',
                school_id: '',
                type: '',
                class_id: ''
            },
            belong: Core.Data.getBelong(),
            deviceID: '',

            activeIndex: 0,
            loading: true,
            active: 1,
            multipleSelection: [],
            multipleSelectionIds: [],
            multipleSelectionChannel: [],
            multipleSelectionChannelNo: [],
            channelList: [],


            editVisible: false,
            editItem: {},
            editForm: {
                type: '',
                name: '',
            },

        };
    },
    watch: {},
    computed: {
        school_id() {
            if (this.auth('SCHOOL')) {
                return Core.Data.getBelong().id;
            } else if (this.auth('ORG_SCHOOL')) {
                return Core.Data.getOrgCurrSchool().id;
            }
        }
    },
    filters: {
        deviceTypeFilter(type) {
            switch (type) {
                case 1: return '班级'
                case 2: return '公共区域'
                default: return '-'
            }
        },
        deviceTypeIconFilter(type) {
            switch (type) {
                case 1: return 'icon-class'
                case 2: return 'icon-public'
                default: return ''
            }
        }
    },
    created() {},
    mounted() {
        this.getTableData();
        if (this.auth('ORG')) {
            this.getSchoolAll();
        }
    },
    methods: {
        auth: Core.Utils.auth,
        getSchoolAll() {
            Core.Api.OrgSystem.getSchoolAll(this.belong.id).then(res => {
                this.schoolList = res.list;
            });
        },
        pageChange(page) {
            this.currPage = page;
            this.getTableData();
        },
        pageSizeChange(size) {
            this.pageSize = size;
            this.getTableData();
        },
        handleSelectionChange(val) {
            console.log('handleSelectionChange val:', val);
            this.multipleSelection = val;
            this.multipleSelectionIds = val.map((item) => item.id);
        },
        handleSelectionChannelChange(val) {
            console.log('handleSelectionChannelChange val:', val);
            this.multipleSelectionChannel = val;
            this.multipleSelectionChannelNo = val.map((item) => {
                return {
                    channel: item.channelNo,
                    name: item.channelName
                };
            });
        },
        getTableData() {
            Core.Api.Device.list(
                this.searchForm.school_id
                    ? this.searchForm.school_id
                    : this.school_id,
                this.searchForm.device_id,
                this.searchForm.name,
                this.searchForm.type,
                this.currPage,
                this.pageSize
            ).then((res) => {
                this.total = res.count;
                this.tableData = res.list;
            });
        },
        showDeviceVisible() {
            this.deviceVisible = true;
            this.clearForm();
        },
        lastStep() {
            this.active--;
        },
        next() {
            if (!this.dialogForm.device_id && this.active === 1) {
                return this.$message.warning('请输入设备序列号');
            }
            this.active++;
            if (this.active === 2) {
                Core.Api.Device.channelList(this.dialogForm.device_id)
                    .then((res) => {
                        console.log(`channelList res:`, res);
                        this.channelList = res.list;
                        this.loading = false;
                    })
                    .catch((error) => {
                        console.log(`error`, error);
                        if (error.data.code === 7) {
                            this.active--;
                        }
                    });
            }
        },
        handleDeviceAdd() {
            const config = {
                id: this.dialogForm.id,
                school_id: this.dialogForm.school_id
                    ? this.dialogForm.school_id
                    : this.school_id,
                channel_list: this.multipleSelectionChannelNo,
                serial_number: this.dialogForm.device_id,
                type: this.dialogForm.type
            };
            console.log(`config`, config);
            if (!config.type) {
                return this.$message.warning('请选择设备类型');
            }
            if (!config.school_id) {
                return this.$message.warning('请选择所属学校');
            }
            let url = '/school/1/camera/batch-create?token='
            if (this.auth('ORG')) {
                url = '/org/1/camera/batch-create?token='
            } else if (this.auth('ADMIN')) {
                url = '/admin/1/camera/batch-create?token='
            }
            axios.post(baseUrl + url + Core.Data.getToken(), JSON.stringify(config)).then((res) => {
                this.$message.success('新增成功');
                this.deviceVisible = false;
                this.closeDeviceViseble()
                this.getTableData();
            });
        },
        async openClassVisible(item) {
            this.classVisible = true;
            this.deviceID = item.id;
            if (item.class_id) {
                this.dialogForm.class_id = item.class_id;
            }
            let taskTime = new Date().getTime()
            this.taskTime = taskTime
            let page = 1
            let classList = []
            while (true) {
                let res = await Core.Api.Device.classBySchool(item.school_id, page++, 10)
                let list = res.list
                if (this.taskTime !== taskTime) {
                    return
                }
                if (!list.length) {
                    return
                }
                classList.push(...list)
                this.classList = classList;
            }
        },
        handleClass() {
            Core.Api.Device.bindClass(
                this.deviceID,
                this.dialogForm.class_id
            ).then((res) => {
                this.$message.success('绑定成功');
                this.classVisible = false;
                this.getTableData();
            });
        },
        routerChange(key, item) {
            switch (key) {
                case 1:
                    this.$router.push('/device/device-detail?id=' + item.id);
                    break;
            }
        },
        clearForm() {
            this.dialogForm.device_id = '';
            this.dialogForm.serial_num = '';
            this.dialogForm.channel = '';
            this.dialogForm.name = '';
            this.dialogForm.school_id = '';
        },

        closeDeviceViseble() {
            this.active = 1;
            this.deviceVisible = false;
            this.channelList = [];
            this.clearForm();
        },
        closeClassViseble() {
            this.classVisible = false;
            this.dialogForm.class_id = '';
        },

        openEditVisible(item) {
            this.editItem = item
            this.editForm = {
                type: item.type,
                name: item.name
            }
            this.editVisible = true;
        },
        closeEditViseble() {
            this.editItem = {}
            this.editForm = {
                type: '',
                name: ''
            }
            this.editVisible = false;
        },
        handleEdit() {
            console.log('this.editForm:', this.editForm)
            if (!this.editForm.name) {
                return this.$message.warning('请输入通道名称')
            }
            Core.Api.Device.save(
                this.editItem.id,
                this.editForm.name,
                this.editItem.serial_number,
                this.editItem.school_id,
                this.editItem.channel,
                this.editItem.serial_number,
                this.editForm.type,
            ).then((res) => {
                this.$message.success('修改成功');
                this.closeEditViseble();
                this.getTableData();
            });
        },
    }
};
</script>

<style lang="scss" scoped>
.container {
    @import '@/common/styles/table.scss';
    .table-container {
        .row {
            display: flex;
            flex-direction: row;
            align-items: center;
            .mr-10 {
                margin-right: 10px;
            }
        }
        .iconfont {
            color: #1d517e;
        }
    }
    .checkbox {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 50px;
        .item {
            text-align: center;
            width: 100px;
            margin-bottom: 10px;
        }
    }

    .form-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        min-height: 32px;
        + .form-item {
            margin-top: 16px;
        }
        .key {
            color: #8090a6;
            font-size: 14px;
            width: 120px;
            text-align: right;
            box-sizing: border-box;
            padding-right: 20px;
        }
        .value {
            width: calc(100% - 120px);
            box-sizing: border-box;
            padding-right: 20px;
        }
        &.channel-name {
            align-items: flex-start;
            .key {
                line-height: 32px;
            }
            .value {
                .form-item-channel {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    .key-channel {
                        width: 80px;
                        box-sizing: border-box;
                        padding-left: 4px;
                        font-size: 14px;
                    }
                    .value-channel {
                        width: calc(100% - 80px);
                    }
                    + .form-item-channel {
                        margin-top: 16px;
                    }
                }
            }
        }
    }
    .body {
        display: flex;
        flex-direction: row;
        .cloumn {
            display: flex;
            flex-direction: column;
            border-right: 1px solid #f2f4f7;
            width: 100px;

            .left {
                display: flex;
                justify-content: center;
                color: #000;
                font-size: 14px;

                .item {
                    cursor: pointer;
                    width: 80px;
                    height: 30px;
                    line-height: 30px;
                }
                &.active {
                    background: #ebedf0;
                    color: #1d517e;
                }
            }
        }
        .right {
            display: flex;
            flex-direction: column;
            .form-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                .coulmn {
                    display: flex;
                    flex-direction: column;
                    .value {
                        width: 240px;
                        margin-bottom: 16px;
                        margin-left: 26px;
                        &.ml-30 {
                            margin-left: 26px;
                        }
                    }
                }
                .key {
                    color: #8090a6;
                    font-size: 14px;
                    margin-bottom: 16px;
                    margin-left: 30px;
                }
            }
        }
    }
}
</style>
<style lang="scss">
.device-dialog .el-dialog__body {
    max-height: 50vh;
    overflow: auto;
}
</style>
